<template>
  <header class="bg-primary text-white py-10">
    <div class="container mx-auto px-4 flex justify-between items-center">
      <!-- <div class="text-xl font-bold">
        <a href="#" class="text-white">GISAN GEFF</a>
      </div> -->
      <nav class="space-x-8 hidden ml-auto md:flex">
        <a href="#" class="font-bold hover:text-secondary my-auto">HOME</a>
        <a href="#" class="font-bold hover:text-secondary my-auto">ABOUT</a>
        <a href="#" class="font-bold hover:text-secondary my-auto">EXPERIENCE</a>
        <a href="#" class="font-bold hover:text-secondary my-auto">SKILLS</a>
        <a href="#" class="font-bold hover:text-secondary my-auto">PROJECTS</a>
        <a
          href="#"
          class="font-bold bg-white text-black py-2 px-4 rounded hover:bg-secondary hover:text-white transition duration-300"
        >
          CONTACT ME
        </a>
      </nav>
      <button
        @click="toggleMenu"
        id="menu-button"
        class="md:hidden text-white focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
    <nav v-show="isMenuOpen" id="mobile-menu" class="md:hidden px-4 pt-4 pb-2 space-y-2">
      <a href="#" class="block font-bold hover:text-gray-400">HOME</a>
      <a href="#" class="block font-bold hover:text-gray-400">ABOUT</a>
      <a href="#" class="block font-bold hover:text-gray-400">EXPERIENCE</a>
      <a href="#" class="block font-bold hover:text-gray-400">SKILLS</a>
      <a href="#" class="block font-bold hover:text-gray-400">PROJECTS</a>
      <a
          href="#"
          class="font-bold bg-white text-black py-2 px-4 rounded hover:bg-secondary hover:text-white transition duration-300"
        >
          CONTACT ME
        </a>
    </nav>
  </header>
</template>

<script>
export default {
    name: "TopNavBar",

    data() {
        return {
            isMenuOpen: false,
        };
    },

    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
    },
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
