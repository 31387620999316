<template>
  <TopNavBar/>
  <SectionHero/>
  <SectionAbout/>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import TopNavBar from './components/TopNavBar.vue'
import SectionHero from './components/sections/Hero.vue'
import SectionAbout from './components/sections/About.vue'
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    TopNavBar,
    SectionHero,
    SectionAbout,
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
