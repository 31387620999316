<template>
  <section class="bg-primary text-white py-20 px-5 md:px-20">
    <div class="container mx-auto">
      <h1 class="text-7xl font-extrabold mb-1">GISAN GEFF</h1>
      <p class="text-3xl text-secondary font-semibold mb-5">Web Developer</p>
      <a
        href="#"
        class="font-bold bg-white text-black py-2 px-6 rounded hover:bg-secondary transition duration-300"
      >
        MY WORKS
      </a>

      <div class="flex flex-row gap-4 mt-10 items-center">
        <a href="https://github.com/nicegood123" target="_blank">
          <img src="@/assets/images/social-icons/github.png" class="w-10" alt="GitHub" />
        </a>
        <a
          href="https://www.linkedin.com/in/gisan-geff-raniego-2464b3318/"
          target="_blank"
        >
          <img
            src="@/assets/images/social-icons/linkedin.png"
            class="w-10"
            alt="LinkedIn"
          />
        </a>
        <a href="https://www.facebook.com/gisangeffmalupa.raniego" target="_blank">
          <img src="@/assets/images/social-icons/meta.png" class="w-10" alt="Facebook" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: "SectionHero",

    data() {
        return {};
    },

    methods: {},
};
</script>

<style scoped>
/* Custom gradient overlay */
</style>
