<template>
  <section class="flex flex-col md:flex-row items-center bg-tertiary py-20 px-5 md:px-20">
    <div class="w-full md:w-2/3 text-justify">
      <h2 class="text-xl font-bold text-gray-800">ABOUT ME</h2>
      <p class="mt-4 text-gray-600">
        I'm a Laravel Web Developer with a strong command of Laravel's MVC architecture
        and Query Builder/Eloquent ORM, my skills include Restful API development and
        third-party integrations. As a hobbyist coder, crafting elegant and efficient
        solutions is not just a job for me; it's a love affair with technology. I believe
        in writing clean, well-documented code that is easy to read and maintain.
      </p>
      <button
        class="mt-6 bg-secondary text-white font-bold py-2 px-4 rounded hover:bg-gray-800"
      >
        DOWNLOAD CV
      </button>

      <div class="mt-7">
        <h2 class="text-xl font-bold text-gray-800">EDUCATION</h2>

        <div class="mt-4">
          <h3 class="font-bold text-gray-800">
            Bachelor of Science in Information Technology
          </h3>
          <p class="text-gray-600">University of Mindanao</p>
          <p class="text-gray-500 font-semibold">2018 – 2023</p>
        </div>

        <div class="mt-4">
          <h3 class="font-bold text-gray-800">
            Information & Communication Technology-SHS
          </h3>
          <p class="text-gray-600">University of Mindanao</p>
          <p class="text-gray-500 font-semibold">2016 – 2018</p>
        </div>

        <div class="mt-4">
          <h3 class="font-bold text-gray-800">Junior High School</h3>
          <p class="text-gray-600">Father Saturnino Urios College of Sibagat Inc.</p>
          <p class="text-gray-500 font-semibold">2012 – 2016</p>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/3 mt-6 md:mt-0 md:ml-6 flex justify-center">
      <!-- <img
        src="@/assets/images/my-workstation.jpg"
        alt="Profile Picture"
        class="w-90 h-150 object-cover border-r-[15px] border-slate-600"
      /> -->

      <div class="relative w-90 h-150">
        <img
          src="@/assets/images/my-workstation.jpg"
          alt="Profile Picture"
          class="w-90 h-150 object-cover rounded border-slate-500"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: "SectionAbout",

    data() {
        return {};
    },

    methods: {},
};
</script>

<style scoped>
/* Custom gradient overlay */
</style>
