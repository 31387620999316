<template>
    <!-- Header Section -->
    <!-- <header class="bg-black text-white py-10">
        <div class="container mx-auto px-4 flex justify-between items-center">
            <div class="text-xl font-bold">
                <a href="#" class="text-white">GISAN GEFF</a>
            </div>
            <nav class="space-x-8 hidden ml-auto md:flex">
                <a href="#" class="font-bold hover:text-gray-400 my-auto">HOME</a>
                <a href="#" class="font-bold hover:text-gray-400 my-auto">ABOUT</a>
                <a href="#" class="font-bold hover:text-gray-400 my-auto">EXPERIENCE</a>
                <a href="#" class="font-bold hover:text-gray-400 my-auto">SKILLS</a>
                <a href="#" class="font-bold hover:text-gray-400 my-auto">PROJECTS</a>
                <a href="#" class="font-bold bg-white text-black py-2 px-4 rounded hover:bg-black hover:border hover:text-white transition duration-300">
                    CONTACT ME
                </a>
            </nav>
            <button id="menu-button" class="md:hidden text-white focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>
        </div>
        <nav id="mobile-menu" class="md:hidden px-4 pt-4 pb-2 space-y-2 hidden">
            <a href="#" class="block font-bold hover:text-gray-400">HOME</a>
            <a href="#" class="block font-bold hover:text-gray-400">ABOUT</a>
            <a href="#" class="block font-bold hover:text-gray-400">EXPERIENCE</a>
            <a href="#" class="block font-bold hover:text-gray-400">SKILLS</a>
            <a href="#" class="block font-bold hover:text-gray-400">PROJECTS</a>
            <a href="#" class="font-bold bg-white text-black py-2 px-6 rounded hover:bg-custom-blue transition duration-300">
                MY WORKS
              </a>
        </nav>
    </header> -->
    

    
    

    <!-- Hero Section -->
    <!-- <section class="bg-black text-white py-20">
        <div class="container mx-auto">
            <h1 class="text-5xl font-bold mb-6">GISAN GEFF</h1>
            <p class="text-2xl mb-8">Web Developer</p>
            <a href="#" class="font-bold bg-white text-black py-2 px-6 rounded hover:bg-custom-blue transition duration-300">
                MY WORKS
              </a>
              
        </div>
    </section> -->

    <!-- Social Media Icons -->
    <div class="fixed top-1/4 left-4 space-y-4">
        <a href="#" class="text-gray-400 hover:text-black">
            <!-- Replace with your icons -->
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><!-- Icon SVG Here --></svg>
        </a>
        <a href="#" class="text-gray-400 hover:text-black">
            <!-- Replace with your icons -->
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><!-- Icon SVG Here --></svg>
        </a>
        <a href="#" class="text-gray-400 hover:text-black">
            <!-- Replace with your icons -->
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><!-- Icon SVG Here --></svg>
        </a>
    </div>

    <!-- About Me Section -->
    <section class="py-20">
        <div class="container mx-auto">
            <h2 class="text-3xl font-bold mb-6">About Me</h2>
            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </section>

<!-- Header mobile view -->
    <!-- <script>
        document.getElementById('menu-button').addEventListener('click', function() {
            var mobileMenu = document.getElementById('mobile-menu');
            mobileMenu.classList.toggle('hidden');
        });
    </script> -->
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
